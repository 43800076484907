const idPortalApi = "aa57eb1617dcecbc206fc55afcc095cd0dabbc0b";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  //config botões e imagens
  dnsPortal: "cmcolinas.ma.gov.br",
  banner_defalt: true, //se não quiser o banner transparencia azul padrao

  NomePortal: "Colinas",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 6, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://cmcolinas.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `
  https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d396073.48357239825!2d-44.25330395!3d-6.08383605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78659f0181a7277%3A0xdaf2073f084d3418!2sColinas%20-%20MA!5e1!3m2!1spt-BR!2sbr!4v1731345979033!5m2!1spt-BR!2sbr
  `,
};
